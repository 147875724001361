function ImageContainer({
  image,
  label,
  openAccordion,
  isMobile,
  bgButton,
}: {
  image?: string
  label?: string
  openAccordion: boolean
  isMobile: boolean
  bgButton: string
}) {
  return (
    <div
      className={`relative md:col-span-1 flex justify-center md:justify-end items-center p-4 ${
        isMobile && openAccordion ? 'h-72' : 'h-0'
      } `}>
      <div
        id="image"
        className={`absolute top-0 rounded-full pt-2 border shadow-lg overflow-hidden transition-all duration-500 ${
          openAccordion || !isMobile
            ? `background-animate w-64 h-72 opacity-100 ${bgButton}`
            : 'w-64 h-0 opacity-0 '
        } flex justify-center items-center`}>
        {image ? (
          <img
            src={image}
            alt={label}
            className="h-full w-auto object-cover flex items-center bottom-0 justify-center absolute"
          />
        ) : (
          <span className="text-sm font-bold text-white uppercase">
            No image
          </span>
        )}
      </div>
    </div>
  )
}

export default ImageContainer
