import SkillElement from '../Section/SkillElement'
import SymfonyLogo from '../../assets/svg/symfony.svg'
import NestJsLogo from '../../assets/svg/nestjs.svg'
import PhpLogo from '../../assets/svg/php.svg'
import ReactLogo from '../../assets/svg/react.svg'
import TypescriptLogo from '../../assets/svg/Typescript.svg'
import JavascriptLogo from '../../assets/svg/javascript.svg'
import BootstrapLogo from '../../assets/svg/bootstrap.svg'
import CssLogo from '../../assets/svg/css.svg'
import TailwindLogo from '../../assets/svg/tailwind.svg'

interface Skill {
  id: number
  skillTitle: string
}

interface LocalData {
  id: number
  bgSection: string
  bgSkill: string
  bgAnchor: string
  bgButton: string
  hrefSection: string
  hrefMoreSection: string
  dataType: string
}

function AnchorLink({
  anchor,
  label,
  closeNavList,
  closeDropdown,
  localData,
  skills,
  isMobile,
}: {
  anchor: string
  label: string
  closeNavList: () => void
  closeDropdown: () => void
  localData?: LocalData
  skills?: Skill[]
  isMobile: boolean
}) {
  const handleClick = () => {
    closeNavList()
    closeDropdown()
  }

  const skillLogos: { [key: string]: any } = {
    React: ReactLogo,
    Symfony: SymfonyLogo,
    NestJS: NestJsLogo,
    PHP: PhpLogo,
    Typescript: TypescriptLogo,
    Javascript: JavascriptLogo,
    Bootstrap: BootstrapLogo,
    CSS: CssLogo,
    Tailwind: TailwindLogo,
  }

  return (
    <li className="flex-col relative flex justify-end items-center ">
      <a
        href={anchor}
        onClick={handleClick}
        className={`p-3 flex justify-center
         gap-1 w-full hover:bg-gradient-to-r from-cyan-500 to-blue-500 hover:text-black`}>
        {skills && skills.length > 0 ? (
          skills.map(skill => (
            <SkillElement
              key={skill.id}
              title={skill.skillTitle}
              langage={skillLogos[skill.skillTitle]}
              bgSkill={localData?.bgAnchor}
            />
          ))
        ) : (
          <span className="pt-1">{label}</span>
        )}
      </a>
    </li>
  )
}

export default AnchorLink
