function SkillElement({
  title,
  langage,
  bgSkill,
}: {
  title: string
  langage: string
  bgSkill?: string
}) {
  return (
    <div
      className={`border ${bgSkill} p-2 h-10 w-10 rounded shadow-lg flex items-center justify-center`}>
      <img src={langage} alt={'logo ' + title} min-width="20" min-height="20" />
    </div>
  )
}

export default SkillElement
